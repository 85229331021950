import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// css Link
import "bootstrap/dist/css/bootstrap.min.css";
import "./discoverpage.css";
import "react-image-lightbox/style.css";

import Image01 from "./Images/SideStone/Side-Stone (1).jpg";
import Image02 from "./Images/SideStone/Side-Stone (2).jpg";
import Image03 from "./Images/SideStone/Side-Stone (3).jpg";
import Image04 from "./Images/SideStone/Side-Stone (4).jpg";
import Image05 from "./Images/SideStone/Side-Stone (5).jpg";

function SideStone() {
  class DiscoverCarousel extends Component {
    render() {
      return (
        <Carousel
          autoPlay={true}
          swipeable={true}
          showArrows={true}
          showThumbs={false}
          useKeyboardArrows={true}
          infiniteLoop={true}
          interval={4000} // 4000
          showStatus={false}
          transitionTime={1500} // 1500
          emulateTouch={true}
        >
          <div>
            <img src={Image01} alt="SideStone" />
          </div>
          <div>
            <img src={Image02} alt="SideStone" />
          </div>
          <div>
            <img src={Image03} alt="SideStone" />
          </div>
          <div>
            <img src={Image04} alt="SideStone" />
          </div>
          <div>
            <img src={Image05} alt="SideStone" />
          </div>
        </Carousel>
      );
    }
  }
  return (
    <div>
      <DiscoverCarousel />
    </div>
  );
}

export default SideStone;
