export const contactUs = {
  url: `/landing/contact-us`,
  method: 'post',
};

export const demand = {
  url: `/landing/demand`,
  method: 'post',
};

export const paginate = {
  url: `/landing/paginate`,
  method: 'post',
};
