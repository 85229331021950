import React, { useState, useEffect } from "react";
import { BiCookie } from "react-icons/bi";

const Cookie = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!document.cookie.includes("cookieConsent")) {
      setVisible(true);
    }
  }, []);

  const setCookie = (name, value, days) => {
    document.cookie = `${name}=${encodeURIComponent(value)}; max-age=${days * 86400}; path=/`;
  };

  const handleAccept = () => {
    setCookie("cookieConsent", "true", 365);
    setVisible(false);
  };

  const handleDecline = () => {
    setCookie("cookieConsent", "false", 7);
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <div className="bg-secondary cookie p-3 rounded">
          <h3 className="mb-3 d-flex align-items-center"><BiCookie /> Cookie Consent</h3>
          <p className="text-muted cookie-content text-justify">
            At Arjiv Exports, we are committed to protecting your privacy and
            providing you with a personalized experience. By clicking <strong>"Accept"</strong>,
            you agree Arjiv Exports can store cookies on your device and disclose information in accordance with our Cookie Policy.
          </p>
          <div className="d-flex justify-content-end">
            <button className="btn btn-secondary mr-2" onClick={handleAccept}>
              Accept
            </button>
            <button className="btn btn-secondary" onClick={handleDecline}>
              Decline
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Cookie;
