import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import sub1 from "./Image/sub-1.jpg";
import sub2 from "./Image/sub-2.jpg";
import sub3 from "./Image/sub-3.jpg";
import sub4 from "./Image/sub-4.jpg";
import sub5 from "./Image/sub-5.jpg";
import custbg from "./Image/cust-bg.jpg";

// popup Form

import "reactjs-popup/dist/index.css";

// Crousel Import

import { Carousel, CarouselItem } from "reactstrap";

// import image1 from "./Image/front-new.jpg";
import image2 from "./Image/bg-2.jpg";
import image3 from "./Image/bg-3.jpg";
import image4 from "./Image/bg-1.jpg";
// import image4 from "./Image/arjivexports.jpg";
import image5 from "./Image/front3.jpg";
import banner from "./Image/website01.jpg";
import banner2 from "./Image/website02.jpg";
import canadaMark from "./Image/canada-mark.jpg";
import rjcBanner from "./Image/rjcBanner.jpg";
// import image5 from "./Image/arjivexports1.jpg";


import "./bodysection.css";
import OrderForm from "./popup";
import VideoPopup from "../../PopupWindow/videopopup";
// import LiveShows from "../LiveEvents";
const items = [
  // {
  //   src: image1,
  //   altText: "Slide 1",
  //   caption: "",
  //   className: "",
  // },
  {
    src: image2,
    altText: "Specialised in matching pairs",
    caption: "Specialised in matching pairs",
    className: "specialised",
  },
  {
    src: image3,
    altText: "ARJIV CREATIONS",
    caption: "ARJIV CREATIONS",
    className: "",
  },
  {
    src: image4,
    altText: "More than 25 years of manufacturing expertise",
    caption: "More than 25 years of manufacturing expertise",
    className: "Morethen",
  },
  // {
  //   src: image5,
  //   altText: "Arjiv Exports",
  //   caption: "",
  //   className: "",
  // },
  // {
  //   src: image5,
  //   altText: "Slide 5",
  //   caption: "",
  //   className: "",
  // },
];

const HeadCrousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [slideText, setSlideText] = useState("Test Slide Text");

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    // setSlideText(item[nextIndex])
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    // setSlideText(item[nextIndex])
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className=""
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        interval={item.interval}
      >
        <img src={item.src} alt={item.altText} className={item.className} />
        {/* <CarouselCaption
          captionHeader={item.caption}
          className={item.className}
        /> */}
        <div class="carousel-caption py-1 image-caption">
          <h2>{item.caption}</h2>
        </div>
      </CarouselItem>
    );
  });

  return (
    <>
      <style>
        {`.custom-tag {
              max-width: 100%;
              height: 500px;
              background: black;
            }
          .image-caption h2{
            font-size: calc(10px + 1vw + 1vh);
            text-transform: uppercase;
            font-family: optima_nova_lt_regular, sans-serif;
            color: #e0dcd9;
            letter-spacing: 4px;
          }`}
      </style>
      <div>
        {/* <div className="float-md-left">Test Text</div> */}
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          className="carousel-fade"
          // interval={3000}
          style={{ height: "636px" }}
        >
          {slides}
        </Carousel>
      </div>
    </>
  );
};

const Home = () => {
  // const PopupSetup = () => {
  //   switch (window.sessionStorage.getItem("popupkey")) {
  //     case null:
  //       window.sessionStorage.setItem("popupkey", "false");
  //       return <VideoPopup />;
  //     case "false":
  //       return "";
  //     default:
  //       return "";
  //   }
  // };

  return (
    <div className="body">
      {/* <PopupSetup /> */}
      <HeadCrousel />
      <div className="nav-2">
        <p className="excellence">EXCELLENCE AND PROFICIENCY</p>
      </div>
      <div className="">
        <div className="imagesection">
          <div className="sub">
            <Row className="subitem" xs={1} sm={1} md={2}>
              <Col className="subImage" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className="subImage">
                  <CardImg src={rjcBanner} alt="Responsible jewellery council (RJC)"></CardImg>
                </Card>
              </Col>
            </Row>
            <Row className="subitem" xs={1} sm={1} md={2}>
              <Col className="subImage" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className="subImage">
                  <CardImg src={canadaMark} alt="Authorised canadamark manufacturer"></CardImg>
                </Card>
              </Col>
            </Row>
            <Row className="subitem" xs={1} sm={1} md={2}>
              <Col className="subImage">
                <Card className="subImage ">
                  <CardImg src={sub1} alt="Brilliant cut diamonds" className=""></CardImg>
                </Card>
              </Col>
              <Col className="subTextpart">
                <Card>
                  <CardBody className="py-5 cardtext">
                    <CardTitle className="subhead">BRILLIANT CUT</CardTitle>
                    <CardText className="subtextbody">
                      <span className="py-5">
                        A brilliant cut is simply a diamond or any other
                        gemstone cut in a particular form with numerous facets
                        so to ensure exceptional brilliance.
                      </span>
                    </CardText>
                    <Button
                      className="subbutton"
                      href="https://trade.arjivexports.com/"
                      target="_blank"
                    >
                      Discover
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="subitem" xs={1} sm={1} md={2}>
              <Col className="subTextpart">
                <Card>
                  <CardBody className="py-5 cardtext">
                    <CardTitle className="subhead">STEP CUT</CardTitle>
                    <CardText className="subtextbody">
                      Stones whose outlines are either square or rectangular and
                      whose facets are rectilinear and arranged parallel to the
                      griddle known as step-or-step cut stones.
                    </CardText>
                    <Button
                      className="subbutton"
                      href="https://trade.arjivexports.com/"
                      target="_blank"
                    >
                      <span>Discover</span>
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col className="subImage">
                <Card className="subImage">
                  <CardImg src={sub2} alt="Step cut diamonds"></CardImg>
                </Card>
              </Col>
            </Row>
            <Row className="subitem" xs={1} sm={1} md={2}>
              <Col className="subImage">
                <Card className="subImage">
                  <CardImg src={sub3} alt="European, Rose and Potrait cut diamonds"></CardImg>
                </Card>
              </Col>
              <Col className="subTextpart">
                <Card>
                  <CardBody className="py-5 cardtext">
                    <CardTitle className="subhead">
                      EUROPEAN CUT / ROSE CUT / PORTRAIT CUT
                    </CardTitle>
                    <CardText className="subtextbody">
                      These are one of the oldest cutting style for diamonds.
                      Modern jewellery designers are creating Contemperary
                      jewellery with an interesting old world vibe or by adding
                      rose cut.
                    </CardText>
                    <Button
                      className="subbutton"
                      href="https://trade.arjivexports.com/"
                      target="_blank"
                    >
                      Discover
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="subitem" xs={1} sm={1} md={2}>
              <Col className="subTextpart">
                <Card>
                  <CardBody className="py-5 cardtext">
                    <CardTitle className="subhead">
                      EXQUISITE DIAMONDS
                    </CardTitle>
                    <CardText className="subtextbody">
                      ARJIV EXPORTS is the home to the world's most exquisite
                      diamonds that continue to underline the legacy.
                    </CardText>
                    <Button
                      className="subbutton"
                      href="https://trade.arjivexports.com/"
                      target="_blank"
                    >
                      Discover
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col className="subImage">
                <Card className="subImage">
                  <CardImg src={sub4} alt="Exquisite diamonds"></CardImg>
                </Card>
              </Col>
            </Row>
            <Row className="subitem" xs={1} sm={1} md={2}>
              <Col className="subImage">
                <Card className="subImage">
                  <CardImg src={sub5} alt="Fancy color diamonds"></CardImg>
                </Card>
              </Col>
              <Col className="subTextpart">
                <Card>
                  <CardBody className="py-5 cardtext">
                    <CardTitle className="subhead">FANCY COLOR</CardTitle>
                    <CardText className="subtextbody">
                      Natural Fancy colored diamonds are as rare as they
                      exquisite. Arjiv exports carries a variety of fancy
                      colours in a range of shapes, sizes colours and clarity.
                    </CardText>
                    <Button
                      className="subbutton"
                      href="https://trade.arjivexports.com/"
                      target="_blank"
                    >
                      Discover
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="bgimage-handler">
        <img
          src={custbg}
          alt="Customize diamonds"
          className="bgimage"
          style={{
            width: "100%",
            fontfamily: "optima_nova_lt_regular, sans-serif",
          }}
        />
        <OrderForm className="" />
      </div>
      <div className="row m0 journeryTitle py-5">
        <h3>
          THE
          <br />
          <span>JOURNEY</span>
        </h3>
        <h6>WELCOME TO THE WORLD OF ARJIV.</h6>
        <br />
        <p>
          Founded in 1985, ARJIV EXPORTS has always believed in Innovation,
          Excellence and Proficiency. Since then,we have been striving to meet
          the highest standards of manufacturing each diamond.
        </p>
      </div>
      <div className="thejourneybutton">
        <Button href="/journey" className="journeybutton" target="_blank">
          Explore Our Journey
        </Button>
      </div>
      {/* <LiveShows /> */}
    </div>
  );
};

export default Home;
