import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { AiOutlineCloseSquare } from "react-icons/ai";
import "./popupb.css";
import ReactPlayer from "react-player";

import newWeb from "./website__3.mp4";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    WebkitOverflowScrolling: "touch",
    backgroundColor: "",
    borderColor: "transparent",
  },
};

function VideoPopup() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 2000);
    // document.getElementById("myvideo").play();
  }, []);

  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   // subtitle.style.color = "#f00";
  // }

  function toggleMute() {
    var video = document.getElementById("myvideo");

    if (video.muted == null) {
      video.muted = false;
    } else {
      debugger;
      video.muted = true;
      video.play();
    }
  }
  useEffect(() => {
    setTimeout(() => toggleMute(), 2000);
    // document.getElementById("myvideo").play();
  }, []);

  return (
    <div>
      <Modal
        closeTimeoutMS={500}
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div>
          <video
            width="600px"
            height="340px"
            loop
            // controls
            autoPlay
            id="myvideo"
            src={newWeb}
          ></video>

          {/* <ReactPlayer
            className="react-player"
            url={newWeb}
            // onBufferEnd={() => alert("Buffer END")}
            id="er"
            controls
            loop
            playing
          /> */}
          <AiOutlineCloseSquare
            style={{
              verticalAlign: "top",
              fontSize: "20px",
              paddingTop: "0px",
              color: "#e0dcd9",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
export default VideoPopup;
