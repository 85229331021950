import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Hongkong from "./Image/show_1.jpg";
import Baseworld from "./Image/show_2.jpg";
import Hktdc from "./Image/show_3.jpg";
import Bharatdiamond from "./Image/show_4.jpg";
import LasVegas from "./Image/show_5.jpg";
// import VideoPopup from "../../PopupWindow/videopopup";


import "./bodysection.css";

function Events() {

  useEffect(() => {
    document.title = "Events and Exhibitions"
  }, [])

  return (
    <div className="main-content py-5">
      <h3>EVENTS UPDATES</h3>
      <p>WE EXHIBIT IN THE FOLLOWING SHOWS</p>
      {/* <VideoPopup /> */}

      <Container>
        <Row xs="1" sm="1" md="2">
          <Col className="p-2">
            <div className="zoom">
              <img src={Hongkong} alt="Hongkong" />
              <p className="bg-secondary p-2 position-relative">GemGeneve</p>
            </div>
          </Col>
          <Col md="6" className="">
            <Row>
              <Col md={6} className="p-2">
              <div className="zoom">
                <img src={Baseworld} alt="Arabia" />
                <p className="bg-secondary p-1 position-relative">Jewellery Arabia</p>
              </div>
              </Col>
              <Col md={6} className="p-2">
                <div className="zoom">
                  <img src={Hktdc} alt="Vincenzaoro" />
                  <p className="bg-secondary p-1 position-relative">Vicenzaoro</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-2">
                <div className="zoom">
                  <img src={Bharatdiamond} alt="Singapore" className="bg-white" />
                  <p className="bg-secondary p-1 position-relative">Jewellery & Gem World Singapore</p>
                </div>
              </Col>
              <Col md={6} className="p-2">
                <div className="zoom">
                  <img src={LasVegas} alt="Miami" />
                  <p className="bg-secondary p-1 position-relative">Miami Beach Antique Show</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Events;
