import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// css Link
import "bootstrap/dist/css/bootstrap.min.css";
import "./discoverpage.css";
import "react-image-lightbox/style.css";

import Image01 from "./Images/RoseCut/Rose-Cut (1).jpg";
import Image02 from "./Images/RoseCut/Rose-Cut (2).jpg";
import Image03 from "./Images/RoseCut/Rose-Cut (3).jpg";
import Image04 from "./Images/RoseCut/Rose-Cut (4).jpg";
import Image05 from "./Images/RoseCut/Rose-Cut (5).jpg";
import Image06 from "./Images/RoseCut/Rose-Cut (6).jpg";
import Image07 from "./Images/RoseCut/Rose-Cut (7).jpg";
import Image08 from "./Images/RoseCut/Rose-Cut (8).jpg";
import Image09 from "./Images/RoseCut/Rose-Cut (9).jpg";
import Image10 from "./Images/RoseCut/Rose-Cut (10).jpg";
import Image11 from "./Images/RoseCut/Rose-Cut (11).jpg";
import Image12 from "./Images/RoseCut/Rose-Cut (12).jpg";
import Image13 from "./Images/RoseCut/Rose-Cut (13).jpg";
import Image14 from "./Images/RoseCut/Rose-Cut (14).jpg";

function RoseCut() {
  class DiscoverCarousel extends Component {
    render() {
      return (
        <Carousel
          autoPlay={true}
          swipeable={true}
          showArrows={true}
          showThumbs={false}
          useKeyboardArrows={true}
          infiniteLoop={true}
          interval={4000} // 4000
          showStatus={false}
          transitionTime={1500} // 1500
          emulateTouch={true}
        >
          <div>
            <img src={Image01} alt="RoseCut" />
          </div>
          <div>
            <img src={Image02} alt="RoseCut" />
          </div>
          <div>
            <img src={Image03} alt="RoseCut" />
          </div>
          <div>
            <img src={Image04} alt="RoseCut" />
          </div>
          <div>
            <img src={Image05} alt="RoseCut" />
          </div>
          <div>
            <img src={Image06} alt="RoseCut" />
          </div>
          <div>
            <img src={Image07} alt="RoseCut" />
          </div>
          <div>
            <img src={Image08} alt="RoseCut" />
          </div>
          <div>
            <img src={Image09} alt="RoseCut" />
          </div>
          <div>
            <img src={Image10} alt="RoseCut" />
          </div>
          <div>
            <img src={Image11} alt="RoseCut" />
          </div>
          <div>
            <img src={Image12} alt="RoseCut" />
          </div>
          <div>
            <img src={Image13} alt="RoseCut" />
          </div>
          <div>
            <img src={Image14} alt="RoseCut" />
          </div>
        </Carousel>
      );
    }
  }
  return (
    <div>
      <DiscoverCarousel />
    </div>
  );
}

export default RoseCut;
