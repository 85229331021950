import React from 'react'
import './loader.css'
const Loader = () => {
  return (
    <div className='loading-bar d-flex align-items-center justify-content-center overflow-hidden'>
      <div className='spinner'></div>
    </div>
  )
}

export default Loader
