import React from "react";
import ReactDOM from "react-dom";
import XMLViewer from "react-xml-viewer";

const xml =
  '<?xml version="1.0" encoding="UTF-8"?><urlset><url><loc>http://arjivexports.com/</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>1.00</priority></url><url><loc>http://arjivexports.com/index.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url><url><loc>http://arjivexports.com/events.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url><url><loc>http://arjivexports.com/journey.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url><url><loc>http://arjivexports.com/policies.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url><url><loc>http://arjivexports.com/rjc.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url><url><loc>http://arjivexports.com/provenance.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url><url><loc>http://arjivexports.com/customization.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url><url><loc>http://arjivexports.com/contact_us.aspx</loc><lastmod>2020-12-07T03:48:39+00:00</lastmod><priority>0.80</priority></url></urlset>';
const customTheme = {
  attributeKeyColor: "#0074D9",
  attributeValueColor: "#2ECC40",
};

const SiteMap = () => {
  return (
    <div
      style={{
        backgroundColor: "#e0dcd9",
        borderTop: "solid 1px ",
        borderBottom: "solid 1px ",
        padding: "10px 50px",
      }}
    >
      <XMLViewer xml={xml} theme={customTheme} />
    </div>
  );
};

export default SiteMap;
