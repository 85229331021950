import React from "react";
import Footer from "./Components/footer";
import HeaderPart from "./Components/header";
import Cookie from "./Components/Cookie";
// import Particles from "react-particles-js";
import ScrollUpButton from "react-scroll-up-button";
import "./Components/style.css";
import { IoIosArrowDropup } from "react-icons/io";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const App = () => {
  return (
    <div>
      <Cookie />
        <ScrollUpButton
          ContainerClassName="backToTop d-flex align-items-center justify-content-center bg-secondary pointer"
          TransitionClassName="backTopTra"
        >
          <IoIosArrowDropup className="w-100" width={40} />
        </ScrollUpButton>
      <HeaderPart />
      <Footer />
    </div>
  );
};

export default App;
