import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
} from "reactstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AiOutlineShareAlt, AiFillWechat } from "react-icons/ai";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaSkype,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";

import "./style.css";

import logo from "../logo.png";
import Contactus from "./pages/contact_us";
import Customization from "./pages/customization";
import Events from "./pages/events";
import Journey from "./pages/journey";
import Policies from "./pages/policies";

// SiteMap URL
import SiteMap from "./pages/xml";

import Provenance from "./pages/provenance";
import Rjcpolicy from "./pages/rjcpolicy";
import Home from "./pages/home";
import BriliantCut from "./discoverpage/BriliantCut";
import OldCut from "./discoverpage/OldCut";
import RoseCut from "./discoverpage/RoseCut";
import SideStone from "./discoverpage/SideStone";
import FancyColor from "./discoverpage/FancyColor";
import StepCut from "./discoverpage/StepCut";
import Headline from "../Extra/headline";
import WhatWeDO from "./pages/whatwedo";
import Exquisite from "./discoverpage/Exquisite";
import AppPolicy from "./pages/app_policy";

const HeaderPart = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePdfDownload = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/Web_Ethical_Business_Policies.pdf`;
    window.open(pdfUrl, '_blank');
  };

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Router>
      <div>
        <Navbar dark expand="lg" center className="nav-1">
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav dark expand="lg" className="mr-auto" navbar>
              <NavItem>
                <NavLink href="/" className="navLink">
                  HOME
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/events" className="navLink">
                  EVENTS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/journey" className="navLink">
                  THE JOURNEY
                </NavLink>
              </NavItem>
              <UncontrolledDropdown
                dark
                nav
                inNavbar
                className="ddown"
                onMouseEnter={isOpen}
              >
                <DropdownToggle nav caret className="navLink">
                  COMPLIANCES
                </DropdownToggle>
                <DropdownMenu dark right>
                  <DropdownItem dark href="/policies" className="dropdown-item">
                    POLICIES - SUPPLY CHAIN
                  </DropdownItem>
                  <DropdownItem href="/rjcpolicy" target="_blank" className="dropdown-item">
                    RJC POLICY
                  </DropdownItem>
				          <DropdownItem className="dropdown-item" onClick={handlePdfDownload}>
                    Web Ethical Business Policies
                  </DropdownItem>
                  <DropdownItem href="/provenance" className="dropdown-item">
                    PROVENANCE CLAIM
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/customization" className="navLink">
                  CUSTOMIZATION
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/whatwedo" className="navLink">
                  What We Do
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact_us" className="navLink">
                  CONTACT US
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          <Nav className="d-flex align-items-end">
            {/* <NavItem>
              <NavLink href="" className="nav-side">
                EN
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavbarText className="nav-side">|</NavbarText>
            </NavItem> */}
            {/* <NavItem>
              <NavLink href="" className="nav-side">
                CN
              </NavLink>
            </NavItem> */}
            {/* <NavItem className=""> */}
              {/* <NavLink
                  href="https://beta.arjivexports.com"
                  className="loginbtn"
                >
                  Login
                </NavLink> */}
              <Button
                href="https://trade.arjivexports.com"
                className="loginbtn mb-0"
                target="_blank"
              >
                Login
              </Button>
            {/* </NavItem> */}

            <UncontrolledDropdown
              dark
              nav
              inNavbar
              className="ddown"
              onMouseEnter={isOpen}
            >
              <DropdownToggle nav caret className="socialmediatogle py-0" aria-label="Social Contacts">
                <NavItem>
                  <NavLink href="" className="nav-side side-icon">
                    <AiOutlineShareAlt aria-label="Social Contacts" />
                  </NavLink>
                </NavItem>
              </DropdownToggle>
              <DropdownMenu dark right className="dropdownmenusocial" aria-label="Socials">
                <DropdownItem dark className="dropdown-item-social">
                  <NavLink
                    target="_"
                    href="https://www.facebook.com/arjivexportsnyc"
                    className=""
                  >
                    <FaFacebookF />
                  </NavLink>
                </DropdownItem>
                <DropdownItem dark className="dropdown-item-social">
                  <NavLink
                    target="_"
                    href="https://www.instagram.com/arjivexportsnyc"
                    className=""
                  >
                    <FaInstagram />
                  </NavLink>
                </DropdownItem>
                {/* <DropdownItem dark className="dropdown-item-social">
                  <NavLink
                    href="https://www.youtube.com/channel/UC4-RsQOjr6hrwjqGp6H6oTA?"
                    className=""
                  >
                    <FaYoutube />
                  </NavLink>
                </DropdownItem> */}
                <DropdownItem dark className="dropdown-item-social">
                  <NavLink target="_blank" href="skype:live:.cid.5f5ec33ba48feb87?chat" className="">
                    <FaSkype />
                  </NavLink>
                </DropdownItem>
                <DropdownItem dark className="dropdown-item-social">
                  <NavLink
                    target="_"
                    href="https://www.linkedin.com/in/arjiv-exports-a38bbb1b6"
                    className=""
                  >
                    <FaLinkedinIn />
                  </NavLink>
                </DropdownItem>
                <DropdownItem dark className="dropdown-item-social">
                  <NavLink
                    target="_"
                    href="https://api.whatsapp.com/send?phone=+917405793333&text=I%27m%20Interested%20in%20buying%20Diamonds%20from%20you"
                    className=""
                  >
                    <FaWhatsapp />
                  </NavLink>
                </DropdownItem>
                <DropdownItem dark className="dropdown-item-social">
                  <NavLink target="_" href="https://u.wechat.com/IEaKFeEc_-6I4QnjDR5qCQo" className="">
                    <AiFillWechat />
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>

        {/* Header-Logo */}
        {/* <Navbar center className="nav-2">
          <Nav className="mr-auto">
            <NavItem>
              <NavLink href="/" className="navLink">
                <img src={logo} className="logo-image" alt="Arjiv Exports" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavbarText className="nav-text">ARJIV</NavbarText>
            </NavItem>
          </Nav>
        </Navbar> */}
        <div className="logo-container">
          <div className="logo-container">
            <div className="logo">
              <p>
                <a href="/" className="arjivhead">
                  <img src={logo} className="img-fluid" alt="" />
                  <h1>Arjiv</h1>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path="/contact_us"
          component={Contactus}
          title="Contact Us Page"
        />
        <Route
          path="/customization"
          component={Customization}
          title="Contact Us Page"
        />
        <Route path="/events" component={Events} />
        <Route path="/journey" component={Journey} />
        <Route path="/policies" component={Policies} />
        <Route path="/provenance" component={Provenance} />
        <Route path="/rjcpolicy" component={Rjcpolicy} />
        <Route path="/briliantcut" component={BriliantCut} />
        <Route path="/oldcut" component={OldCut} />
        <Route path="/rosecut" component={RoseCut} />
        <Route path="/sidestone" component={SideStone} />
        <Route path="/fancycolor" component={FancyColor} />
        <Route path="/stepcut" component={StepCut} />
        <Route path="/whatwedo" component={WhatWeDO} />
        <Route path="/exquisite" component={Exquisite} />
        <Route path="/sitemap" component={SiteMap} />
        <Route path="/app_policy" component={AppPolicy} />
      </Switch>
    </Router>
  );
};

export default HeaderPart;
